@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../sass/custom";

.CurrentBannersList {
	width: 100%;
	height: auto;

	&_header {
		width: 100%;
		height: auto;
		margin-bottom: 6rem;

		&_title {
			font-size: 2.5rem;
			font-weight: 700;
			color: map-get($map: $blueMap, $key: "600");
		}
		&_desc {
			font-size: 1.7rem;
			font-weight: 600;
			color: map-get($map: $blueGreyMap, $key: "600");
		}
	}
	&_search {
		width: 100%;
		height: auto;
		@include flex_row(center, center);
		gap: 0 2rem;
		margin-bottom: 2rem;

		&_input {
			width: 40rem;
			height: 3.5rem;
			border-radius: 5rem;
			font-size: 1.6rem;
			font-weight: 500;
			padding-left: 0.7rem;
			border: none;
			outline: none;
			&:hover {
				cursor: pointer;
				box-shadow: map-get($map: $shadows, $key: "mainOutline");
			}
			&:focus-within {
				cursor: pointer;
				box-shadow: map-get($map: $shadows, $key: "mainOutline");
			}
		}
	}
}
